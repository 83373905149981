import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/store-modules';
import WelcomePage from '@/components/welcome/Welcome';

import SignupPage from '@/components/auth/Signup';
import SigninPage from '@/components/auth/Signin';
import PasswordReset from '@/components/auth/PasswordReset';
const { isNavigationFailure, NavigationFailureType } = Router;

Vue.use(Router);

const router = new Router({
  routes: [
    {
      name: 'welcome',
      path: '/',
      component: WelcomePage,
      meta: {
        title: 'Welcome',
      },
    },
    {
      name: 'passwordReset',
      path: '/passwordReset',
      component: PasswordReset,
      meta: {
        title: 'Password Reset',
      },
    },
    {
      name: 'signup',
      path: '/signup',
      component: SignupPage,
      meta: {
        title: 'Sign Up',
      },
    },
    {
      name: 'signin',
      path: '/signin',
      component: SigninPage,
      props: true,
      meta: {
        title: 'Login',
      },
    },
    {
      name: 'signout',
      path: '/signout',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/auth/Logout'),
      meta: {
        title: 'Logout',
      },
    },
    {
      name: 'dashboard',
      path: '/dashboard',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/dashboard/Dashboard'),
      meta: {
        requiresAuth: true,
        title: 'Dashboard',
      },
    },
    {
      name: 'feedbackViewer',
      path: '/feedbackViewer',
      component: () =>
        import(
          /* webpackPrefetch: true */ '@/components/omw/feedback/FeedbackViewer'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'feedback',
        title: 'Feedback Viewer',
      },
    },
    {
      name: 'omwLookup',
      path: '/omwLookup',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/omw/lookup/OmwLookup'),
      meta: {
        requiresAuth: true,
        specificAuth: 'omwLookup',
        title: 'On My Way Lookup',
      },
    },
    {
      name: 'omwResult',
      path: '/omwResult',
      component: () =>
        import(
          /* webpackPrefetch: true */ '@/components/omw/lookup/OmwLookupResult'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'omwLookup',
        title: 'On My Way',
      },
    },
    {
      name: 'omwStats',
      path: '/omwStats',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/omw/stats/OmwStats'),
      meta: {
        requiresAuth: true,
        specificAuth: 'omwStats',
        title: 'On My Way Statistics',
      },
    },
    {
      name: 'marketing',
      path: '/marketing',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/omw/marketing/ManageCampaigns'
        ),
      meta: {
        requiresAuth: true,
        specificAuth: 'marketing',
        title: 'Appointment Assist Marketing Campaigns',
      },
    },

    {
      name: 'admin',
      path: '/admin',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/admin/UserAdmin'),
      meta: {
        requiresAuth: true,
        specificAuth: 'superUser,customerAdministrator',
        title: 'Administration',
      },
    },

    {
      name: 'supportSubmission',
      path: '/supportSubmission',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/admin/SupportForm'
        ),
      meta: {
        requiresAuth: true,
        title: 'Submit Support Ticket',
      },
    },
    {
      name: 'omwResourceConfig',
      path: '/omwResourceConfig',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/omw/resources/DisplayResourceConfig'
        ),
      meta: {
        requiresAuth: true,
        title: 'OMW Resource Config',
      },
    },
    {
      name: 'verifyUser',
      path: '/verifyUser',
      component: () =>
        import(
          /* webpackPrefetch: true */
          '@/components/auth/Verification'
        ),
      meta: {
        requiresAuth: false,
        title: 'Verify User',
      },
      props: true,
    },
    {
      name: 'default',
      path: '*',
      component: () =>
        import(/* webpackPrefetch: true */ '@/components/dashboard/Dashboard'),
      meta: {
        requiresAuth: true,
        title: 'Dashboard',
      },
    },
  ],
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  if (to.name === 'signin') {
    next();
    return;
  }
  const storedUser = store.getters['storeAuth/user'];
  const isAuthenticated = store.getters['storeAuth/isAuthenticated'];
  // const verifiedUser = store.getters['storeAuth/isAuthenticated'];
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // No user stored and needs auth - send to sign in page

  // We don't need authentication
  if (!requiresAuth) {
    // We're logged in and the target route doesn't require authorisation
    // send the user to where they asked for
    next();
    return;
  }

  // We're logged in and the target route doesn't require authorisation
  if (!isAuthenticated && requiresAuth) {
    next('/signin').catch((failure) => {
      if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
        // show a small notification to the user
        console.warn('Login in order to access the admin panel');
      }
    });
    return;
  }

  // We're logged in and the target route DOES require authorisation
  to.matched.some((record) => {
    // We're logged in and the target route DOES require authorisation
    // and if there's a specific level of auth required
    if (record.meta.specificAuth) {
      // We're logged in and the target route DOES require authorisation
      // and specific authorisation is needed
      // and the current user has that authorisation
      let specificAuthFound = false;
      const specificAuthArray = record.meta.specificAuth.split(',');
      for (let i = 0; i < specificAuthArray.length; i++) {
        if (storedUser[specificAuthArray[i]]) {
          specificAuthFound = true;
          break;
        }
      }
      if (specificAuthFound) {
        next();
        return;
      }
      // Leadent or super user so allow access
      else if (storedUser.leadent || storedUser.superUser) {
        next();
        return;
      } else {
        // We're logged in and the target route DOES require authorisation
        // and there's a specific level of auth required
        // and the user DOES NOT have the level of auth required
        // so send to the dashboard
        next({
          name: 'dashboard',
        });
        return;
      }
    }
    // No specific auth needed if we get here
    next();
  });
});

export default router;
