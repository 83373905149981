export default {
  namespaced: true,
  state: {
    shouldShowRelease: false,
  },
  mutations: {
    SET_VIEW_RELEASE(state, shouldShow) {
      state.shouldShowRelease = shouldShow;
    },
  },
  actions: {
    setViewRelease({ commit }, shouldShow) {
      commit('SET_VIEW_RELEASE', shouldShow);
    },
  },
  getters: {
    shouldShowRelease: (state) => state.shouldShowRelease,
  },
};
