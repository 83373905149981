import { Auth, API } from 'aws-amplify';
import axios from 'axios';
import amplifyConfig from '@/amplify-config';
import configData from '@/config/config.json';

export async function getCampaigns(includeArchived) {
  return API.get(
    'omw',
    `/marketing?includeArchived=${String(includeArchived)}`,
  );
}

export async function createCampaign(
  { name, isPublished = false, clickUrl, categories, isDefault, isArchived },
  file,
) {
  const campaignPayload = {
    body: {
      name,
      isPublished,
      clickUrl,
      categories,
      isDefault,
      isArchived,
    },
  };
  // We use axios directly to allow for mutliple bucket configurations and to be able to test locally
  // as this is not currently supported by Amplify
  const formData = new FormData();
  formData.append('image', file);

  const base64Content = await blobToBase64(file);
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  // const urlFriendlyFilename = encodeURIComponent(file.name);
  const url = amplifyConfig.apiGateway.URL + `/marketing/image/${file.name}`;
  const { data } = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
  campaignPayload.body.imageUrl = data.url;
  await API.post('omw', '/marketing', campaignPayload);
}

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export async function getClickDetailForCampaign(campaignName) {
  const data = await API.get(
    'omw',
    `/marketing/clicked/detail/${campaignName}`,
  );
  return data[campaignName];
}

export async function updateCampaign({
  campaignName,
  clickUrl,
  categories,
  isDefault,
}) {
  const campaignPayload = {
    body: {
      clickUrl,
      categories,
      isDefault,
    },
  };
  return API.patch('omw', `/marketing/${campaignName}`, campaignPayload);
}

export async function archiveCampaign(campaignName) {
  return API.patch(
    'omw',
    `/marketing/${campaignName}/archive/?isArchived=true`,
  );
}
export async function unarchiveCampaign(campaignName) {
  return API.patch(
    'omw',
    `/marketing/${campaignName}/archive?isArchived=false`,
  );
}

export async function unpublishCampaign(campaignName) {
  return API.patch('omw', `/marketing/${campaignName}/unpublish`);
}

export async function publishCampaign(campaignName) {
  return API.patch('omw', `/marketing/${campaignName}/publish`);
}

export async function makeCampaignDefault(campaignName) {
  return API.patch('omw', `/marketing/${campaignName}/default`);
}

export async function restrictCampaign(campaignName) {
  return API.patch('omw', `/marketing/${campaignName}/restrict`);
}

export async function getClickDataForCampaign(campaignName) {
  return API.get('omw', `/marketing/clicked/${campaignName}`);
}
