<template>
  <nav
    class="navbar has-background-header"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item has-background-header"
        href="https://www.leadent.digital"
        target="_new"
      >
        <div class="svg-container">
          <company-logo v-if="logoIsSvg" style="max-height: 50px" />
          <img v-else :src="logo" style="max-height: 50px" />
        </div>
      </a>
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="portal-navbar"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="portal-navbar" class="navbar-menu has-background-customer">
      <div class="navbar-start">
        <div class="buttons">
          <router-link
            v-if="
              isAuthenticated &&
              currentRoute != 'signin' &&
              currentRoute != 'dashboard'
            "
            to="/dashboard"
            class="navbar-item button is-primary nav-item"
            >{{ $t('page-title-dashboard') }}</router-link
          >
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <div
            v-if="
              isAuthenticated &&
              currentRoute != 'signin' &&
              currentRoute != 'welcome'
            "
            class="navbar-item nav-item"
          >
            <p class="has-text-weight-semibold has-text-header-text">
              {{ $configData.customer.displayName }}
            </p>
          </div>
          <p
            v-if="
              isAuthenticated &&
              currentRoute != 'signin' &&
              currentRoute != 'welcome'
            "
            class="navbar-item has-text-weight-semibold has-text-header-text"
          >
            {{ email }}
          </p>
          <div class="buttons">
            <div
              v-if="
                isAuthenticated &&
                currentRoute != 'signin' &&
                currentRoute != 'welcome'
              "
              class="navbar-item nav-item"
            >
              <b-button type="is-primary" @click="onLogout">
                {{ $t('logout-button-label') }}
              </b-button>
            </div>
            <div
              v-if="!isAuthenticated && currentRoute !== 'welcome'"
              class="navbar-item nav-item"
            >
              <b-button
                v-if="!isAuthenticated && currentRoute !== 'signup'"
                type="is-primary"
                @click="onSignUp"
              >
                {{ $t('user-admin-signup') }}
              </b-button>
              <b-button
                v-if="!isAuthenticated && currentRoute !== 'signin'"
                type="is-primary"
                @click="onSignIn"
              >
                {{ $t('user-admin-login-button-label') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import CompanyLogo from '@/assets/img/logo.svg';

export default defineComponent({
  components: {
    CompanyLogo,
  },
  computed: {
    ...mapGetters('storeAuth', ['isAuthenticated', 'user']),
    email() {
      if (this.user) {
        return this.user.email;
      } else {
        return null;
      }
    },
    logoIsSvg() {
      return this.$configData.customer?.logoName?.includes('.svg');
    },
    logo() {
      return require(`@/assets/img/${this.$configData.customer?.logoName}`);
    },
  },
  methods: {
    ...mapActions('storeAuth', ['logout']),
    onSignUp() {
      this.$router.push({
        name: 'signup',
      });
    },
    onSignIn() {
      this.$router.push({
        name: 'signin',
      });
    },
    async onLogout() {
      await this.logout();
      this.$router
        .replace({
          name: 'signout',
        })
        .catch(() => {
          // nothing to do
        });
    },
  },
});
</script>

<style scoped>
:root {
  --color1: black;
}
.logo {
  font-weight: bold;
  color: white;
}
.logo a {
  text-decoration: none;
  color: white;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
}
li {
  margin: 0 16px;
}
li a {
  text-decoration: none;
  color: white;
}
li a:hover,
li a:active,
li a.router-link-active {
  color: #fa923f;
}
.logout {
  background-color: transparent;
  border: none;
  font: inherit;
  color: white;
  cursor: pointer;
}
.svg-container svg {
  width: 100%;
}
</style>
