function initialState() {
  return {
    feedbackData: [],
  };
}

export default {
  namespaced: true,
  state: {
    feedbackData: initialState().feedbackData,
  },
  mutations: {
    STORE_FEEDBACK_DATA: (state, data) => {
      state.feedbackData = data;
    },
    DISCARD_FEEDBACK: (state) => {
      while (state.feedbackData.length > 0) {
        state.feedbackData.pop();
      }
    },
  },
  actions: {
    storeFeedbackData({ commit }, data) {
      commit('STORE_FEEDBACK_DATA', data);
    },
    discardFeedback({ commit }) {
      commit('DISCARD_FEEDBACK');
    },
  },
  getters: {
    feedback: (state) => state.feedbackData,
  },
};
