/**
 * This file is used to list the Vuex modules that are required to be persisted in sessionStorage
 */
const persistedStateModules = [
  'storeAuth',
  'storeFeedback',
  'storeLanguage',
  'storeMarketing',
  'storeOmwLookup',
  'storeReleases',
];

export default persistedStateModules;
