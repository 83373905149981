import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import enUS from './enUS';
import de from './de';
import es from './es';
import it from './it';
import fr from './fr';
import dateFormats from './dateFormat';
const dateTimeFormats = dateFormats;

Vue.use(VueI18n);

const locale = navigator.language;

const messages = {
  en: en,
  enUS,
  de: de,
  it: it,
  fr: fr,
  es: es,
  'en-GB': en,
  'en-US': enUS,
  'de-DE': de,
  'it-IT': it,
  'fr-FR': fr,
  'es-ES': es,
};

const i18n = new VueI18n({
  locale,
  dateTimeFormats,
  messages,
  fallbackLocale: 'en-GB',
  silentTranslationWarn: true,
});

export default i18n;
