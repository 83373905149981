<!--
  Component that allows users to reset their password
-->
<template>
  <auth-container>
    <template #authForm>
      <validation-observer v-slot="{ invalid }" ref="passValObs">
        <form @submit.prevent="">
          <b-field :label="$t('user-admin-instructions')">
            <validation-provider
              v-slot="{ errors }"
              ref="passResetValProv"
              name="Email"
              rules="required|email"
            >
              <b-input
                v-model="email"
                name="email"
                icon="at"
                @input="clearMessage"
              ></b-input>
              <span class="help is-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-field>
          <b-field>
            <b-button
              v-if="!submitted"
              :disabled="invalid"
              type="is-primary"
              @click="sendPasswordResetEmail"
            >
              {{ $t('user-admin-reset-password-button-label') }}
            </b-button>
            <div v-else>
              <label class="label has-text-left">{{
                $t('user-admin-password-label')
              }}</label>
              <validation-provider
                v-slot="{ errors }"
                rules="passwordValidator|required"
                name="Password"
              >
                <b-field>
                  <b-input
                    id="password"
                    v-model="newPassword"
                    type="password"
                    icon="key"
                    autocomplete="new-password"
                  ></b-input>
                </b-field>
                <span
                  style="min-height: 1rem"
                  class="help is-danger has-text-weight-bold"
                  >{{ errors[0] }}</span
                >
              </validation-provider>

              <label class="label has-text-left">{{
                $t('user-admin-verification-code')
              }}</label>
              <b-field>
                <validation-provider
                  v-slot="{ errors }"
                  rules="length:6|required"
                  name="verificationCode"
                >
                  <b-input
                    id="verification-code"
                    v-model="verificationCode"
                    type="numeric"
                  ></b-input>
                  <span
                    style="min-height: 1rem"
                    class="help is-danger has-text-weight-bold"
                    >{{ errors[0] }}</span
                  >
                </validation-provider>
              </b-field>
              <b-button
                :disabled="!submitted"
                type="is-primary"
                @click="setNewPassword"
              >
                {{ $t('user-admin-update-password-button-label') }}
              </b-button>
            </div>
          </b-field>
          <p v-if="error" class="is-danger">
            {{ error }}
          </p>
          <p v-else-if="submitted">
            {{ message }}
          </p>
          <router-link v-if="showLoginLink" to="signin" class="mb-1"
            >{{ $t('user-admin-verified-go-to-login') }}
          </router-link>
        </form>
      </validation-observer>
    </template>
  </auth-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import AuthContainer from './AuthContainer';
import { mapActions } from 'vuex';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';
import { required, email, length } from 'vee-validate/dist/rules';

setInteractionMode('aggressive');

export default defineComponent({
  components: {
    AuthContainer,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: undefined,
      error: undefined,
      submitted: false,
      newPassword: undefined,
      verificationCode: undefined,
      showLoginLink: false,
    };
  },
  computed: {
    message() {
      return (
        this.$t('If') +
        ' ' +
        this.email +
        ' ' +
        this.$t(
          "is a registered email you'll receive a message with a reset link shortly",
        )
      );
    },
  },
  created() {
    extend('email', {
      ...email,
      message: this.$t('user-admin-enter-valid-email'),
    });
    extend('required', {
      ...required,
      message: this.$t('user-admin-email-required'),
    });
    extend('passwordValidator', {
      message: (field) => {
        return `${this.$configData.signup.passwordValidation.message}`;
      },
      validate: (value) => {
        const re = new RegExp(this.$configData.signup.passwordValidation.regex);
        return re.test(value);
      },
    });
    extend('password', {
      params: ['target'],
      validate(value, { target }) {
        return value === target;
      },
      message: this.$t(`user-admin-passwords-no-match`),
    });
    extend('length', length);
  },
  methods: {
    ...mapActions('storeAuth', ['resetPassword', 'updatePassword']),
    clearMessage() {
      this.submitted = false;
    },
    async sendPasswordResetEmail() {
      try {
        await this.resetPassword(this.email);
        this.submitted = true;
      } catch (err) {
        console.error(err);
        this.error = this.$t('user-admin-password-reset-failure');
        return err;
      }
    },
    async setNewPassword() {
      this.showLoginLink = false;
      try {
        await this.updatePassword({
          email: this.email,
          verificationCode: this.verificationCode,
          password: this.newPassword,
        });
        this.showLoginLink = true;
      } catch (err) {
        this.error = err.message;
      }
    },
  },
});
</script>
