// import { getRollupStats, getFailedMessages } from '../services/stats';
import moment from 'moment';
import config from '../config/config';

function initialState() {
  return {
    rollupStats: [],
    total: 0,
    failedMessages: [],
  };
}

function convertStatusAbbreviation(abbr) {
  switch (abbr) {
    case 'U': {
      return 'Undeliverable';
    }
    case 'E': {
      return 'Expired';
    }
    case 'F': {
      return 'Failed';
    }
    case 'S': {
      return 'Unknown';
    }
    default: {
      return 'Unknown';
    }
  }
}

export default {
  namespaced: true,
  initialState: initialState,
  state: {
    rollupStats: initialState().rollupStats,
    total: initialState().total,
    failedMessages: initialState().failedMessages,
  },
  mutations: {
    SET_ROLLUP_STATS: (state, stats) => {
      state.rollupStats = stats;
    },
    SET_TOTAL: (state, total) => {
      state.total = total;
    },
    DISCARD_ROLLUP_STATS: (state) => {
      state.rollupStats = initialState().rollupStats;
      state.total = initialState().total;
    },
    SET_FAILED_MESSAGES: (state, failedMessages) => {
      state.failedMessages = failedMessages;
    },
  },
  actions: {
    async getFailedMessages({ commit }, { dateFrom, dateTo }) {
      let dateFromStr;
      let dateToStr;
      if (dateFrom && dateTo) {
        dateFromStr = moment(dateFrom).format('YYYY-MM-DD');
        dateToStr = moment(dateTo).format('YYYY-MM-DD');
      }

      const response = await getFailedMessages(dateFromStr, dateToStr);
      const data = response.data;
      const storedVersion = [];
      data.forEach((row) => {
        storedVersion.push({
          apptNumber: row.apptNumber,
          statusTime: row.statusTime,
          createdDateTime: row.createdDateTime,
          status: convertStatusAbbreviation(row.status),
        });
      });
      commit('SET_FAILED_MESSAGES', storedVersion);
    },
    discardRollupStats({ commit }) {
      commit('DISCARD_ROLLUP_STATS');
    },
    // async getRollupStats({ commit }, { dateFrom, dateTo }) {
    //   let data;
    //   if (config.msgStats.demo) {
    //     data = require('../../data/stats.json');
    //   } else {
    //     let dateFromStr;
    //     let dateToStr;
    //     if (dateFrom && dateTo) {
    //       dateFromStr = moment(dateFrom).format('YYYY-MM-DD');
    //       dateToStr = moment(dateTo).format('YYYY-MM-DD');
    //     }
    //     const result = await getRollupStats(dateFromStr, dateToStr);
    //     data = result.data;
    //   }
    //   const total = data.total;
    //   const rollup = {
    //     Failed: data.failed,
    //     Undeliverable: data.undeliverable,
    //     Expired: data.expired,
    //     Delivered: data.delivered,
    //     Unknown: data.sent,
    //   };
    //   commit('SET_ROLLUP_STATS', rollup);
    //   commit('SET_TOTAL', total);
    // },
  },
  getters: {
    rollupStats: (state) => state.rollupStats,
    totalMsgs: (state) => state.total,
    failedMessages: (state) => state.failedMessages,
  },
};
