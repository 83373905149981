const config = {
  apiGateway: {
    REGION: 'eu-west-2',
    URL: 'https://q42bjd0d2f.execute-api.eu-west-2.amazonaws.com/acme-demo',
  },
  cognito: {
    REGION: 'eu-west-2',
    USER_POOL_ID: 'eu-west-2_Vp5UXKffk',
    APP_CLIENT_ID: '6i4lm32pr9qv9pdjm11h0i03or',
    IDENTITY_POOL_ID: 'eu-west-2:8cdb848d-2bd0-47ef-815b-14c4dd87a63f',
  },
};
export default config;
