<!--
  Component for the registration of new users in the portal
-->
<template>
  <ValidationObserver v-slot="{ valid }" ref="valobs">
    <auth-container>
      <template #authForm>
        <form @submit.prevent="onSubmit">
          <b-field>
            <ValidationProvider
              v-slot="{ errors }"
              name="Email"
              rules="required|emailValidator"
            >
              <label class="label has-text-left">{{
                $t('user-admin-email')
              }}</label>
              <b-input
                id="email"
                v-model="email"
                type="email"
                icon="at"
                autocomplete="email"
              ></b-input>
              <span
                style="min-height: 1rem"
                class="help is-danger has-text-weight-bold"
                >{{ errors[0] }}</span
              >
            </ValidationProvider>
          </b-field>
          <validation-provider
            v-slot="{ errors }"
            rules="passwordValidator|required|password:@confirm"
            name="Password"
          >
            <label class="label has-text-left">{{
              $t('user-admin-password')
            }}</label>
            <b-field>
              <b-input
                id="password"
                v-model="password"
                type="password"
                icon="key"
                autocomplete="new-password"
              ></b-input>
            </b-field>
            <span
              style="min-height: 1rem"
              class="help is-danger has-text-weight-bold"
              >{{ errors[0] }}</span
            >
          </validation-provider>
          <label class="label has-text-left">{{
            $t('user-admin-confirm-password')
          }}</label>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="confirm"
          >
            <b-field>
              <b-input
                id="confirm-password"
                v-model="confirmPassword"
                type="password"
                icon="key"
                autocomplete="new-password"
              ></b-input>
            </b-field>
            <span
              style="min-height: 1rem"
              class="help is-danger has-text-weight-bold"
              >{{ errors[0] }}</span
            >
          </validation-provider>
          <div class="control">
            <b-button
              :disabled="!valid"
              type="is-info"
              @click.prevent="onSubmit()"
            >
              {{ $t('user-admin-signup') }}
            </b-button>
          </div>
          <span
            :class="{
              help: true,
              'is-info': !error,
              'is-danger': error,
              'has-text-weight-bold': true,
            }"
          >
            {{ displayMessage }}
            <div v-if="accountExists">
              <router-link
                :to="{ name: 'passwordReset' }"
                class="has-text-primary"
                >{{ $t('user-admin-reset-password-link') }}</router-link
              >
            </div>
            <br />
            <router-link
              :to="{ name: 'verifyUser' }"
              class="has-text-primary"
              >{{ $t('user-admin-validate-user') }}</router-link
            >
          </span>
        </form>
      </template>
    </auth-container>
  </ValidationObserver>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters, mapActions } from 'vuex';
import AuthContainer from './AuthContainer';
import { length, required } from 'vee-validate/dist/rules';

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';

setInteractionMode('aggressive');

export default defineComponent({
  components: {
    AuthContainer,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      displayMessage: null,
      error: false,
      accountExists: false,
      submitted: false,
    };
  },
  computed: {
    ...mapGetters('storeAuth', ['authUser']),
  },
  created() {
    extend('emailValidator', {
      message: () => {
        return this.$t('user-admin-incorrect-domain');
      },
      validate: (value) => {
        const re = new RegExp(this.emailDomainRegex);
        return re.test(value);
      },
    });
    extend('passwordValidator', {
      message: () => {
        return `${this.$t('validation-password-complexity')}`;
      },
      validate: (value) => {
        const re = new RegExp(this.$configData.signup.passwordValidation.regex);
        return re.test(value);
      },
    });
    extend('password', {
      params: ['target'],
      validate(value, { target }) {
        return value === target;
      },
      message: this.$t('user-admin-passwords-no-match'),
    });
    extend('length', {
      ...length,
      message: this.$t('validation-password-complexity'),
    });
    extend('required', required);
  },
  methods: {
    ...mapActions('storeAuth', ['signup']),
    async onSubmit() {
      this.submitted = true;
      this.error = false;
      this.accountExists = false;
      const userData = {
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
      };
      this.submitted = true;
      try {
        await this.signup(userData);
        this.$router
          .push({
            name: 'verifyUser',
            params: {
              providedEmail: this.email,
            },
          })
          .catch(() => {
            /* */
          });
      } catch (err) {
        this.error = true;
        if (err.code === 'UsernameExistsException') {
          this.displayMessage = this.$t(`user-admin-email-exists`);
          this.accountExists = true;
        } else if (err.code === 'InvalidPasswordException') {
          this.displayMessage = this.$t(`user-admin-weak-password`);
          this.displayMessage += `\n${err.message}`;
        } else {
          this.displayMessage = `${this.$t(
            'Oops, an error occurred, please contact your administrator reporting this error',
          )}: ${err.message}`;
        }
      }
    },
  },
});
</script>

<style scoped>
.hero {
  opacity: 0.7;
}
</style>
