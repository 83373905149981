<!--
  Component for logging into the portal
-->
<template>
  <section class="hero">
    <div class="hero-body grayscale">
      <div class="tile is-ancestor">
        <div class="tile is-parent"></div>
        <div class="tile is-parent">
          <div class="has-text-centered tile is-ancestor">
            <div class="tile is-parent"></div>
            <div id="signup" class="tile is-parent is-12">
              <div class="signin-form box">
                <slot name="authForm"></slot>
              </div>
            </div>
            <div class="tile is-parent"></div>
          </div>
        </div>
        <div class="tile is-parent"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      loginSuccess: null,
      loginError: null,
    };
  },
});
</script>

<style scoped>
.hero {
  opacity: 0.7;
}

.signin-form {
  width: 500px;
  margin: 30px auto;
  border: 1px solid #eee;
  padding: 20px;
  box-shadow: 0 2px 3px #ccc;
}
</style>
