<script>
// Main use for this mixin is to provide global visibility of access authorisation
import axios from 'axios';
import { mapGetters } from 'vuex';
import packageJson from '../../package.json';

export default {
  data() {
    return {
      version: packageJson.version,
      shouldShowReleaseNotes: false,
    };
  },
  computed: {
    ...mapGetters('storeAuth', ['isAuthenticated', 'products', 'user']),
    auth() {
      return this.isAuthenticated;
    },
    customer() {
      return this.$configData.customer.name;
    },
    resourceConfigEnabled() {
      return true;
    },
    purchasedFeedback() {
      return this.checkProductPurchased('feedback');
    },
    purchasedOmw() {
      return this.checkProductPurchased('omw');
    },
    purchasedOmwStats() {
      return this.checkProductPurchased('omwStats');
    },
    purchasedMarketing() {
      return this.checkProductPurchased('marketing');
    },
    emailDomainRegex() {
      return this.$configData.signup.emailDomainRegex;
    },
    emailDomain() {
      return this.$configData.signup.emailDomain;
    },
    marketingAccess() {
      return !this.user ? false : this.user.marketing || false;
    },
    omwLookupAccess() {
      return !this.user ? false : this.user.omwLookup || false;
    },
    omwStatsAccess() {
      return !this.user ? false : this.user.omwStats || false;
    },
    feedbackAccess() {
      return !this.user ? false : this.user.feedback || false;
    },
    customerAdminAccess() {
      return !this.user ? false : this.user.customerAdministrator || false;
    },
    resourceConfigAccess() {
      return !this.user ? false : this.user.resourceConfig || false;
    },
    adminAccess() {
      if (!this.user) {
        return false;
      } else {
        return this.user.leadent || this.user.superUser || false;
      }
    },
    leadentAccess() {
      if (!this.user) {
        return false;
      } else {
        return this.user.leadent || false;
      }
    },
    configData() {
      return this.$configData;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    pluralise(label, value) {
      const returnVal = value !== 1 ? label : label.slice(0, -1);
      return returnVal;
    },
    handleCsvFields(fields) {
      const fieldObjectArray = fields.map((field) => {
        return {
          label: field,
          title: field,
        };
      });
      this.activityFields = fieldObjectArray;
    },
    showReleaseNotes() {
      this.shouldShowReleaseNotes = true;
    },
    checkProductPurchased(key) {
      return this.products?.[key];
    },
    emptyArray(arr) {
      if (arr) {
        while (arr.length > 0) {
          arr.pop();
        }
      }
      return arr;
    },
    isErrorUnauthorised(err) {
      if (
        err &&
        err.response &&
        err.response.status &&
        err.response.status === 401
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
