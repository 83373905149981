import { extend, localize } from 'vee-validate';
import configData from '@/config/config.json';

export function initialiseValidation(i18n) {
  extend('required', {
    validate(value) {
      return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1,
      };
    },
    message: i18n('validation-required-message'),
    computesRequired: true,
  });

  const dict = {
    custom: {
      email: {
        regex: `${i18n('validation-email-domain')} ${
          configData.signup.emailDomain
        }`,
      },
      'confirm-password': {
        is: `${i18n('validation-passwords-match')}`,
      },
    },
  };

  localize('en', dict);

  extend('verify_password', {
    getMessage: () => i18n('validation-password-complexity'),
    validate: (value) => {
    let passwordRegex = new RegExp(configData.signup.passwordValidation.regex); //eslint-disable-line
      return passwordRegex.test(value);
    },
  });
}
