/**
 * This is a convenience file for autoimporting all files (other than this one and the persistedState selector) into
 * the main store as modules. They should be named store-something.js which will be converted to
 * a namedspaced module identified as storeSomething.
 */
import camelCase from 'lodash/camelCase';
import persistedStateModules from './persistedStateModules';
const requireModule = require.context('.', false, /\.js$/);
const modules = {};

function getStateKeys(moduleName, moduleContents) {
  const keys = Object.keys(moduleContents.state);
  return keys.map((key) => {
    return `${moduleName}.${key}`;
  });
}

requireModule.keys().forEach((fileName) => {
  // Don't register this file as a module
  if (fileName === './index.js' || fileName === './persistedStateModules.js')
    return;

  const moduleName = camelCase(fileName.replace(/(\.\/|\.js)/g, ''));
  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName).default,
  };
});

/**
 * We only want to persist the state to sessionStorage for selected modules
 * Therefore we use the list defined in persistedStateModules.js to determine which to include
 * in the vuex-persistedstate path property.  This is mainly because the Activity Migrator can extract
 * more data than can be held within sessionStorage quota
 */
const modulesForState = Object.entries(modules);
const stateToPersist = [];

modulesForState.forEach((module) => {
  if (!persistedStateModules.includes(module[0])) return;
  const stateKeys = getStateKeys(module[0], module[1]);
  stateToPersist.push(...stateKeys);
});

export { modules, stateToPersist };
