import '@fortawesome/fontawesome-pro/js/all.js';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faText,
  faUserHardHat,
  faFileCheck,
  faFileTimes,
  faSearch,
  faArrowToLeft,
  faWarehouseAlt,
  faSyncAlt,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faFileDownload,
  faLightbulb,
  faAt,
  faKey,
  faMapMarkedAlt,
  faFunnelDollar,
  faComments,
  faChartPie,
  faUpload,
  faCarSide,
  faStreetView,
  faLock,
  faDatabase,
  faTools,
  faQuestionCircle,
  faServer,
  faCalendarMinus,
  faAngleLeft,
  faAngleRight,
  faCalendarPlus,
  faSitemap,
  faTimes,
  faCheck,
  faPencilAlt,
  faDownload,
  faArrowUp,
  faCalendarDay,
  faParking,
  faRoute,
  faWindowClose,
  faChartBar,
  faChartLine,
  faCloudUploadAlt,
  faEye,
  faTag,
  faArrowsAltH,
  faTimesCircle,
  faUndo,
  faTrash,
  faCaretRight,
  faCalendar,
  faMinus,
  faSadTear,
  faClipboardCheck,
  faMapPin,
  faRuler,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faWarehouse,
  faSearchMinus,
  faSearchPlus,
  faFileCsv,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faFileDownload,
  faLightbulb,
  faAt,
  faKey,
  faMapMarkedAlt,
  faFunnelDollar,
  faComments,
  faChartPie,
  faUpload,
  faCarSide,
  faStreetView,
  faLock,
  faDatabase,
  faTools,
  faQuestionCircle,
  faServer,
  faCalendarMinus,
  faAngleLeft,
  faAngleRight,
  faCalendarPlus,
  faSitemap,
  faTimes,
  faCheck,
  faPencilAlt,
  faDownload,
  faArrowUp,
  faCalendarDay,
  faParking,
  faRoute,
  faSearch,
  faWindowClose,
  faChartBar,
  faChartLine,
  faCloudUploadAlt,
  faEye,
  faTag,
  faArrowsAltH,
  faTimesCircle,
  faUndo,
  faTrash,
  faCaretRight,
  faText,
  faCalendar,
  faMinus,
  faSadTear,
  faUserHardHat,
  faFileCheck,
  faFileTimes,
  faClipboardCheck,
  faRuler,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faWarehouseAlt,
  faWarehouse,
  faSearchMinus,
  faSearchPlus,
  faFileCsv,
  faSyncAlt,
);
