import configData from '../config/config.json';
import {
  getClickDataForCampaign,
  getCampaigns,
  getClickDetailForCampaign,
} from '@/services/marketing/index';

const customer = configData.customer.name;

export default {
  namespaced: true,
  state: {
    campaigns: [],
    clicks: [],
    campaignClicks: {},
    campaignClickDetail: {},
  },
  mutations: {
    SET_CAMPAIGNS(state, payload) {
      state.campaigns = payload;
    },
    SET_CAMPAIGN_CLICKS(state, payload) {
      state.campaignClicks[payload.name] = payload.data;
    },
    SET_CAMPAIGN_CLICK_DETAIL(state, payload) {
      if (payload?.data && payload?.campaignName) {
        state.campaignClickDetail[payload.campaignName] = payload.data;
      }
    },
  },
  actions: {
    async getMarketingCampaigns({ commit }, isArchived) {
      const campaigns = await getCampaigns(isArchived);
      commit('SET_CAMPAIGNS', campaigns);
    },
    getClickDataForCampaigns({ state, commit }) {
      state.campaigns.forEach(async (campaign) => {
        const result = await getClickDataForCampaign(campaign.name);
        commit('SET_CAMPAIGN_CLICKS', {
          name: campaign.name,
          data: result,
        });
      });
    },
    async getClickDataDetail({ commit }, campaignName) {
      const data = await getClickDetailForCampaign(campaignName);
      commit('SET_CAMPAIGN_CLICK_DETAIL', {
        campaignName,
        data,
      });
    },
  },
  getters: {
    campaigns: (state) => state.campaigns,
    campaignClicks: (state) => state.campaignClicks,
    campaignClickDetail: (state) => state.campaignClickDetail,
  },
};
