import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
Vue.use(VueCompositionApi);
import Buefy from 'buefy';
import moment from 'moment';
import 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import copy from 'fast-copy';
import VueFuse from 'vue-fuse';

import Amplify, { Auth, I18n } from 'aws-amplify';
import amplifyConfig from './amplify-config';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: amplifyConfig.cognito.REGION,
    userPoolId: amplifyConfig.cognito.USER_POOL_ID,
    identityPoolId: amplifyConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: amplifyConfig.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'omw',
        endpoint: amplifyConfig.apiGateway.URL,
        region: amplifyConfig.apiGateway.REGION,
        custom_header: async () => {
          const token = (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
    ],
  },
});

import utils from './mixins/utils';
import i18nMixin from './mixins/i18n';
import i18n from './lang/lang.js';
import configData from '@/config/config.json';
import store from './store/store-modules';
import App from '@/App.vue';
import './fontawesome';
import router from './router/index';
import { initialiseValidation } from './veeValidate';
import globalData from './mixins/globalData';

Vue.component('VueFontawesome', FontAwesomeIcon);

Bugsnag.start({
  apiKey: configData.bugsnag.apiKey,
  plugins: [new BugsnagPluginVue(Vue)],
});

Vue.prototype.$moment = moment;
Vue.$moment = moment;
Vue.prototype.$copy = copy;
Vue.$copy = moment;
Vue.$configData = Object.freeze(configData);
Vue.prototype.$configData = Object.freeze(configData);

Vue.mixin(globalData);
Vue.mixin(i18nMixin);
Vue.mixin(utils);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});
Vue.use(VueFuse);

export const app = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App),
});

initialiseValidation(app.$t.bind(app));
