/*
  Used to deal with maintaining state for logged in users
*/
import { Auth, API } from 'aws-amplify';
import copy from 'fast-copy';

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    userId: undefined,
    user: undefined,
    email: undefined,
    users: [],
    products: {},
    validated: false,
    selectedTab: 0,
    authUser: undefined,
  },
  mutations: {
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_AUTH_USER(state, authUser) {
      state.authUser = authUser;
    },
    SET_SELECTED_TAB(state, tab) {
      state.selectedTab = tab;
    },
    SET_VALIDATED(state, validated) {
      state.validated = validated;
    },
    ADD_PRODUCTS(state, products) {
      state.products = products;
    },
    DISCARD_PRODUCTS(state) {
      state.products = {};
    },
    AUTH_USER(state, userData) {
      state.userId = userData.userId;
      state.email = userData.email;
    },
    STORE_USER_DATA(state, user) {
      state.user = user;
    },
    CLEAR_AUTH_DATA(state) {
      state.userId = null;
      state.user = null;
      state.email = null;
      state.selectedAppointment = null;
      state.appointments = {};
    },
    STORE_USER: (state, remoteUser) => {
      // Search for an existing user with same Id and replace
      const users = copy(state.users);
      let existing = false;
      for (let i = 0; i < users.length; i++) {
        if (users[i].key === remoteUser.key) {
          users.splice(i, 1, remoteUser);
          existing = true;
          break;
        }
      }
      // New user to add to the array
      if (!existing) {
        users.push(remoteUser);
      }
      state.users = users;
    },
    DISCARD_USERS: (state) => {
      while (state.users.length > 0) {
        state.users.pop();
      }
    },
    ADD_USER: (state, user) => {
      state.users.push(user);
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async updatePassword({}, { email, verificationCode, password }) {
      await Auth.forgotPasswordSubmit(email, verificationCode, password);
    },
    // eslint-disable-next-line no-empty-pattern
    async signup({}, userData) {
      const { user } = await Auth.signUp({
        username: userData.email,
        password: userData.password,
        attributes: {
          email: userData.email,
        },
      });
    },
    async verifyUser({ commit, state, dispatch }, { username, code }) {
      try {
        await Auth.confirmSignUp(username, code);
      } catch (err) {
        console.error(err);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async resendVerificationCode({}, { username }) {
      try {
        await Auth.resendSignUp(username);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    setAuthUser({ commit }, authUser) {
      commit('SET_AUTH_USER', authUser);
    },
    setSelectedTab({ commit }, tab) {
      commit('SET_SELECTED_TAB', tab);
    },
    async getProducts({ commit }) {
      const productResults = await API.get('omw', '/products');
      commit('ADD_PRODUCTS', productResults);
      return productResults;
    },
    // eslint-disable-next-line no-empty-pattern
    async resetPassword({}, email) {
      await Auth.forgotPassword(email);
    },
    async login({ commit, dispatch }, userData) {
      try {
        const user = await Auth.signIn(userData.email, userData.password);
        commit('SET_VALIDATED', user.attributes.email_verified);
        commit('SET_IS_AUTHENTICATED', true);
        commit('AUTH_USER', {
          userId: user.username,
          email: user.attributes.email,
        });
        await dispatch('fetchUser', user.userId, false);
      } catch (error) {
        throw error;
      }

      // Clear any products before retrieving new ones
      await commit('DISCARD_PRODUCTS');
      await dispatch('getProducts');
    },
    // async loginSaml({ commit, dispatch }, userData) {
    //   try {
    //     // Invalidate session when browser window is closed
    //     await firebase
    //       .auth()
    //       .setPersistence(firebase.auth.Auth.Persistence.SESSION);

    //     const provider = new firebase.auth.SAMLAuthProvider(
    //       configData.auth.saml.provider,
    //     );
    //     const res = await firebase.auth().signInWithPopup(provider);

    //     const idToken = res.credential.a;
    //     const userId = res.user.uid;
    //     const email = res.user.email;
    //     commit('AUTH_USER', {
    //       userId,
    //       email,
    //     });

    //     if (!res.user.emailVerified) {
    //       const error = new Error('Not verified');
    //       error.code = 'Not verified';
    //       return Promise.reject(error);
    //     } else {
    //       commit('SET_VALIDATED', true);
    //     }
    //     try {
    //       await dispatch('fetchUser', userId, false);
    //     } catch (err) {
    //       console.log(err);
    //     }

    //     // Clear any products before retrieving new ones
    //     await commit('DISCARD_PRODUCTS');

    //     await dispatch('getProducts');
    //     router.replace({
    //       name: 'dashboard',
    //       params: {
    //         success: 'yes',
    //       },
    //     });
    //   } catch (err) {
    //     return Promise.reject(err);
    //   }
    // },
    // async loginOidc({ commit, dispatch }, userData) {
    //   try {
    //     // Invalidate session when browser window is closed
    //     await firebase
    //       .auth()
    //       .setPersistence(firebase.auth.Auth.Persistence.SESSION);

    //     const provider = new firebase.auth.OAuthProvider(
    //       configData.auth.odic.provider,
    //     );
    //     const res = await firebase.auth().signInWithPopup(provider);
    //     const idToken = await res.credential.a;
    //     const userId = res.user.uid;
    //     const email = res.user.email;
    //     commit('AUTH_USER', {
    //       userId,
    //       email,
    //     });

    //     if (!res.user.emailVerified) {
    //       const error = new Error('Not verified');
    //       error.code = 'Not verified';
    //       return Promise.reject(error);
    //     } else {
    //       commit('SET_VALIDATED', true);
    //     }
    //     try {
    //       await dispatch('fetchUser', userId, false);
    //     } catch (err) {
    //       console.log(err);
    //     }

    //     // Clear any products before retrieving new ones
    //     await commit('DISCARD_PRODUCTS');

    //     await dispatch('getProducts');
    //     router.replace({
    //       name: 'dashboard',
    //       params: {
    //         success: 'yes',
    //       },
    //     });
    //   } catch (err) {
    //     return Promise.reject(err);
    //   }
    // },
    async logout({ commit }) {
      try {
        commit('CLEAR_AUTH_DATA');
        commit('DISCARD_USERS');
        commit('DISCARD_PRODUCTS');
        commit('SET_IS_AUTHENTICATED', false);
        await Auth.signOut();
      } catch (err) {
        // nothing to do
      }
    },
    async fetchUser({ state, commit }) {
      const userId = state.userId;
      const result = await API.get('omw', `/users/${userId}`);
      commit('STORE_USER_DATA', copy(result));
    },
    async fetchUsers({ dispatch, state, commit }) {
      const result = await API.get('omw', `/users`);
      await dispatch('discardUsers');
      result.forEach((user) => {
        commit('ADD_USER', user);
      });
    },
    discardUsers({ commit }) {
      commit('DISCARD_USERS');
      return Promise.resolve(true);
    },
  },
  getters: {
    user: (state) => state.user,
    users: (state) => state.users,
    userId: (state) => state.userId,
    products: (state) => state.products,
    selectedTab: (state) => state.selectedTab,
    authUser: (state) => state.authUser,
    isAuthenticated: (state) => state.isAuthenticated,
  },
};
